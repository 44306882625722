<template>
    <div class="d-flex align-end">
        <v-container class="pa-0">
            <div class="w-full">
                <div v-if="false" class="app__info d-flex justify-center align-center flex-column bronze--text login-header w-full">
                    {{ appTitle }}
                    <small class="d-block special-blue login-subtitle">{{ appSlogan }}</small>
                </div>
                <div class="app__info d-flex justify-center align-center flex-column bronze--text login-header w-full">
                    
                <v-img src="images/2022-LA-Agency-Logotipo_Gray.png" lazy-src="images/2022-LA-Agency-Logotipo_Gray.png" width="50%" :aspect-ratio="0.7" style="margin-bottom: -20%; margin-top: -30%;"/>
                </div>

            </div>
        </v-container>
    </div>
</template>

<script>
  export default {
    name: 'Header',
    data() {
      return {
        appTitle: this.$store.getters.appTitle,
        appSlogan: this.$store.getters.appSlogan
      }
    }
  }
</script>

<style scoped>

</style>

<template>
    <v-container fluid class="pa-0  login-background-image" fill-height>
        <v-layout row wrap>

          
            <div class="login-window">
                <v-card class="mx-auto blur-card" :shaped="true" :elevation="24" style="background-color: rgba(255, 255, 255, 0.2) !important;"> 
                  <!-- Header with App Info -->
                  <Header/>
                  <!-- end -->
                  <!-- Content -->
                  <v-container class="pa-0">
                      <div class="w-1-2 login-credentials">
                          <ValidationObserver v-slot="{ handleSubmit }">
                              <form @submit.prevent="handleSubmit(submit)">
                                  <v-layout column>
                                      <v-flex>
                                          <ValidationProvider rules="required|email" v-slot="{ errors }">
                                              <v-text-field
                                                      filled
                                                      id="email"
                                                      name="email"
                                                      type="email"
                                                      :label="$t('login.EMAIL')"
                                                      v-model="email"
                                                      :error="errors.length > 0"
                                                      :error-messages="errors[0]"
                                                      autocomplete="off"
                                                      class="login_class"
                                              ></v-text-field>
                                          </ValidationProvider>
                                      </v-flex>
                                      <v-flex>
                                          <ValidationProvider rules="required|min:5" v-slot="{ errors }">
                                              <v-text-field
                                                      filled
                                                      id="password"
                                                      name="password"
                                                      type="password"
                                                      :label="$t('login.PASSWORD')"
                                                      v-model="password"
                                                      :error="errors.length > 0"
                                                      :error-messages="errors[0]"
                                                      autocomplete="off"
                                                      class="login_class"
                                              ></v-text-field>
                                          </ValidationProvider>
                                      </v-flex>
                                      <v-flex text-center mt-2 mb-3>
                                          <SubmitButton :custom-class="'pl-12 pr-12'" :buttonText="$t('login.LOGIN')"/>
                                      </v-flex>
                                      <v-flex text-center>
                                          <v-btn
                                                  :to="{ name: 'forgotPassword' }"
                                                  small
                                                  text
                                                  class="font-weight-medium white--text"
                                          >{{ $t('login.FORGOT_PASSWORD') }}
                                          </v-btn
                                          >
                                      </v-flex>
                                  </v-layout>
                              </form>
                          </ValidationObserver>
                      </div>
                  </v-container>
              </v-card>
            </div>
            <!-- end -->
            <ErrorMessage/>
        </v-layout>
    </v-container>
</template>

<script>
  import router from '@/router'
  import { mapActions } from 'vuex'
  import Header from '@/components/auth/Header'

  export default {
    components: { Header },
    metaInfo() {
      return {
        title: this.$store.getters.appTitle,
        titleTemplate: `${this.$t('login.TITLE')} - %s`
      }
    },
    data() {
      return {
        email: '',
        password: ''
      }
    },
    methods: {
      ...mapActions(['userLogin']),
      async submit() {
        await this.userLogin({
          email: this.email,
          password: this.password
        })
        
      }
    },
    created() {
      if (this.$store.state.auth.isTokenSet) {
        router.push({ name: 'home' })
      }
    }
  }
</script>
